import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Space} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

import './styles/Home.css';
import wholesaleDistributionCenter from '../images/wholesale-distribution-center.jpg';
import mailpersonWithPackage from '../images/mailperson-with-package.jpg';
import warehouse from '../images/warehouse.jpg';
import foodAndBev from '../images/food-and-beverage-import.jpg';
import offshoreWorkersNigeria from '../images/offshore-workers-nigeria-1.jpg';
import shippingJapanBeautiful from '../images/shipping-japan-beautiful.jpg';
import hawaiiBeach from '../images/hawaii-beach.jpg';
import walletWithLogo from '../images/wallet-with-logo-3.jpeg';
import homeworkOverseas from '../images/record-keeping.jpg';


const UseCases = () => {

    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(auth.loginDetails) {
          navigate('/transactions')
        }
      }, []);

    return (
        <div>
            <Row justify="center">
                <Col>
                    
                    {/* upgrade your wallet  */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Versatile and useful. </h2>
                                    
                            <p className="auth-title-text">
                                Punkypay is the <Link class="light-blue" to="/cost-breakdown">cheapest and fastest</Link> way of paying someone internatioanlly. 
                            </p>
                            <p className="auth-title-text">
                                How will Punkypay help you?
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={walletWithLogo} alt="walletOfFuture"/>
                        </div>
                    </div> {/* end container */}
                    {/* 
                        Upgrade your business. 
                        There are so many use cases for Punkypay that we can't mention every one of them.
                        How will Punkypay help your business?
                        MAYBE THROW IN A LINK TO SALES@PUNKYPAY.COM, AFTER I START SELLING THIS
                    */}

                    {/* wholesale distribution */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Paying Offshore Workers </h2>
                            <p className="auth-title-text">
                                International wire transfers and other traditional payment methods often come with hefty fees, unfavorable exchange rates, and take days to complete. Punkypay offers significantly lower transaction costs, much better exchange rates, and nearly instant payments. This means more of the funds you intend to send abroad actually reach your workers.
                            </p>

                            <p className="auth-title-text">
                                Let’s imagine your company is paying an offshore worker $10,000. 
                                If you were paying them via a credit card, about 7%, or $700, would be spent on transaction fees. 
                                If you were paying via bank wire, 4%, or $400, would be spent on transaction fees. 
                                If you were paying via Punkypay, about 0.1%, or $10, would be spent on transaction fees. 
                                This saves your company $690 that goes straight into your profit margin, no effort needed.
                            </p>
                            <p className="auth-title-text">
                                Not sure what these fees come from? <Link class="light-blue" to="/cost-breakdown">Click here</Link> to find out. 
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={offshoreWorkersNigeria} alt="offshore-workers-nigeria"/>
                        </div>
                    </div> {/* end container */}

                    {/* paying offshore workers */}
                    <div className="container">
                        <div className="text-container">
                        <h2 className="auth-title-text"> Wholesale Distribution </h2>
                            <p className="auth-title-text">
                                Wholesalers who import products in bulk can optimize their international transactions by using Punkypay. 
                                This results in cost savings up to 10%, and drastically improves your company’s bottom line. 
                            </p>

                            {/* <p className="auth-title-text">
                                Let’s imagine your company buys textiles from abroad and sells them domestically. 
                                If your company buys $1,000,000 of textiles and you have to pay 3% in fees to make this transaction, your company will spend $30,000. 
                                If your company used Punkypay to make the transaction, we would charge just 1%, or $10,000.  
                                This saves your company $20,000 that goes straight into your profit margin, no effort needed.
                            </p> */}
                        </div>
                        <div className="image-container">
                        <img src={wholesaleDistributionCenter} alt="wholesaleDistributionCenter"/>
                        </div>
                    </div> {/* end container */}

                    {/* Living or Studying Abroad */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Living or Studying Abroad </h2>
                            <p className="auth-title-text">
                                Dealing with the banking system while you're abroad can be slow, expensive, and <i>frustrating</i>. 
                                Trust us, we've been there. 
                            </p>
                            <p className="auth-title-text">
                                Punkypay was originally designed for people living internationally. 
                                Punkypay moves your money overseas in minutes, costs a tiny fraction of what you would otherwise pay, and delivers a much more reliable experience.   
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={homeworkOverseas} alt="homework-overseas"/>
                        </div>
                    </div> {/* end container */}

                    {/* Hotel and tour payments */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Hotel and Tour Payments </h2>
                            <p className="auth-title-text">
                                Travelers can use Punkypay to book and pay for hotels, tours, and activities at their destination. 
                                This eliminates the need to carry large amounts of cash or rely on alternative payment methods, streamlining the booking process.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={hawaiiBeach} alt="hawaii-beach"/>
                        </div>
                    </div> {/* end container */}

                    {/* e-commerce business expansion */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> E-Commerce Business Expansion </h2>
                            <p className="auth-title-text">
                                Suppose you run an e-commerce store and want to expand your product line by sourcing unique items from international suppliers. 
                                You can use Punkypay to pay your overseas suppliers quickly, securely, and at a fraction of the cost compared to traditional bank transfers.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={mailpersonWithPackage} alt="mailpersonWithPackage"/>
                        </div>
                    </div> {/* end container */}


                    {/* food and beverage import */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Food and Beverage Import </h2>
                            <p className="auth-title-text">
                                Businesses that import specialty food and beverages from abroad can benefit from the speed and cost savings of Punkypay, ensuring a more competitive pricing structure.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={foodAndBev} alt="food-and-beverage-import"/>
                        </div>
                    </div> {/* end container */}


                    {/* manufacturing components */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Manufacturing Components </h2>
                            <p className="auth-title-text">
                                If you're a manufacturer, you may need to import specific components or raw materials from overseas to keep your production running smoothly. 
                                Punkypay ensures that payments are processed rapidly and affordably, allowing you to maintain a competitive edge.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={warehouse} alt="warehouse"/>
                        </div>
                    </div> {/* end container */}




                    {/* dropshipping */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Dropshipping </h2>
                            <p className="auth-title-text">
                                If you're involved in dropshipping, where you source products from international suppliers and sell them online without holding inventory, Punkypay can facilitate faster and more cost-effective payments to your suppliers.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={shippingJapanBeautiful} alt="shipping-japan-beautiful"/>
                        </div>
                    </div> {/* end container */}

                </Col>
            </Row>
        </div>
    )
}

export default UseCases;

