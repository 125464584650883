import { Button, Row, Col, Form, Input, Alert, Divider, Typography } from "antd";
import React, { useState, useRef } from "react";
import Countdown from 'react-countdown';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { storeCryptoAccountInMongo, confirmCryptoAccountOTP, resendCryptoAccountOTP } from "../rest/cryptoAccount";
import { getCryptoAccount } from '../store/actions/cryptoAccount.action';
import { useAuth } from "../contexts/auth-context";
import { pad } from '../helpers/helpers';


const CryptoAccountOTPConfirmation = ({ onComplete, onError, defaultError }) => {
    const { Text } = Typography;
    const auth = useAuth();
    const countdownDateRef = useRef(Date.now() + 2*60*1000); // 2 minutes in milleseconds (2*60*1000)
    const [countdownKey, setCountdownKey] = useState(1);
    const [otp, setOtp] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(null);
    const [error, setError] = useState(defaultError);
    const [inProgress, setInProgress] = useState(false);
    const [resendingOTP, setResendingOTP] = useState(false);
    const [showResendOTP, setShowResendOTP] = useState(true); // Shown by default
    const dispatch = useDispatch();

    // GK 2023-10-22 - confirms the crypto account 
    const confirmCryptoAccount = async () => {
        setShowSuccessMessage(null);
        setInProgress(true);
        try {
            await confirmCryptoAccountOTP(otp);

            dispatch(getCryptoAccount());
            const message = <div>
                OTP confirmed! Login successful. <Link to="/transactions">Go to Transactions</Link>
            </div>
            setShowSuccessMessage(message);
            setShowResendOTP(false);
            setInProgress(false);
            setError(null)
            if (onComplete) {
                onComplete();
            }
        } catch (err) {
            let errorMessage = 'Something went wrong confirming the OTP. Try again';

            if (err?.response?.data?.errorCode) {
                errorMessage = err.response.data.message;
            }

            setError(errorMessage);
            setInProgress(false);
            if (onError) {
                onError(errorMessage);
            }
        }
    }

    // GK 2023-10-22 - resends OTP if you hit the resend OTP button
    const resendOTP = async () => {
        setResendingOTP(true);
        try {
            await resendCryptoAccountOTP();
            setError(null);
            setShowSuccessMessage(
                `OTP successfully resent! Please check your email ${auth.currentUser.email}. Don't forget to check your spam!`
            );
            setResendingOTP(false);
            // GK 2023-10-22 -  the countdown makes sure you cannot resend it until after 2 minutes
            countdownDateRef.current = Date.now() + 2*60*1000;  // 2 minutes in milleseconds (2*60*1000)
            setCountdownKey(key => key + 1);
        } catch (err) {
            let errorMessage = 'Something went wrong sending the OTP. Try again';

            if (err?.response?.data?.errorCode) {
                errorMessage = err.response.data.message;
            }

            setError(errorMessage);
            setResendingOTP(false);
        }
    }

    // react Countdown renderer callback
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return (
                <div style={{marginBottom: '10px'}}>
                    <Button 
                        type="link"
                        loading={resendingOTP}
                        onClick={resendOTP}
                    > 
                        Didn't receive any OTP ? Resend
                    </Button>
                </div>
            )
        } else {
            // Render a countdown
            return <div style={{marginBottom: '10px'}}>
                <Text>Resend OTP in {pad(minutes)}:{pad(seconds)}</Text>
            </div>;
        }
    };

    // GK 2023-10-22 - shows resend option ocne the countdown hits 0
    const showResendOTPOption = () => {
        if(!showResendOTP) return null;
    
        return <Countdown 
                    key={countdownKey}
                    date={countdownDateRef.current} 
                    renderer={renderer}
                    onComplete={() => {}}
                />
      }

    const onFinish = (value) => {
        confirmCryptoAccount();
    }

    const onFinishFailed = () => {
        console.log('Finished failed');
    }

    return (
        <div>
            <h2> Confirm OTP Form</h2>
            {
                showSuccessMessage && (
                    <Row>
                        <Col
                            xs={{span: 24}}
                            md={{span: 16, offset: 4}}
                            style={{marginBottom: "20px"}}
                        >
                            <Alert
                                    type="success"
                                    message={showSuccessMessage}
                                />
                        </Col>
                    </Row>
                )
            }
            {
                error && (
                    <Row>
                        <Col
                            xs={{span: 24}}
                            md={{span: 16, offset: 4}}
                            style={{marginBottom: "20px"}}
                        >
                            <Alert message={error} type="error" />
                        </Col>
                    </Row>
                )
            }
            
            <Form
                name="basic"
                labelCol={{
                    xs: {span: 24},
                    md: {span: 8}
                }}
                wrapperCol={{
                    xs: {span: 24},
                    md: {span: 8},
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Enter the OTP"
                    name="otp"
                    rules={[
                        {
                            required: true,
                            message: "Please input the OTP (one time password) sent to your email. Don't forget to check your spam!",
                        },
                    ]}
                    className="form-item"
                >
                    <Input onChange={(e) => setOtp(e.target.value)} />
                </Form.Item>
                <Form.Item
                    className="form-item form-item__submit"
                    wrapperCol={{
                      md: {
                        offset: 8,
                        span: 16,
                      },
                      xs: {
                        span: 24,
                      },
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={inProgress} disabled={resendingOTP}>
                        Save
                    </Button>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        md: {
                          offset: 8,
                          span: 16,
                        },
                        xs: {
                          span: 24,
                        },
                    }}
                >
                    {
                        showResendOTPOption()
                    }
                    <div style={{ marginTop: '5px' }}>
                        <Link to="/crypto-login">Crypto login</Link>
                        <Divider type="vertical" />
                        <Link to="/transactions">Transactions</Link>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CryptoAccountOTPConfirmation;