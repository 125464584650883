import axios from 'axios';


// GK 2023-10-22 - gets coinbase authentication token
export const getCoinbaseToken = (code, redirect_uri) => {
    
  return axios.get(`/api/coinbase/access-token?code=${code}&redirect_uri=${redirect_uri}`);
};

// GK 2023-10-22 - gets user data from coinbase
// GK 2023-10-22 - Note: this API call is now deprecated, I may want to change this
export const getUserData = (coinbaseAuthToken) => {
    
  return axios.get('/api/coinbase/user', {
      headers: {
        'Coinbase-Access-Token': coinbaseAuthToken,
        'Content-Type': 'application/json'
      }
    }).then(response => response.data);
};

export const getAccountData = (coinbaseAuthToken, currency) => {
  const apiURL = currency ? `/api/coinbase/accounts/${currency}` : '/api/coinbase/accounts';

  return axios.get(apiURL, {
    headers: {
      'Coinbase-Access-Token': coinbaseAuthToken,
      'Content-Type': 'application/json'
    }
  }).then(response => response.data);
};

// GK 2023-10-22 - gets crypto addresses\
// https://docs.cdp.coinbase.com/sign-in-with-coinbase/docs/api-addresses/
export const getAddresses = async (coinbaseAuthToken, walletId, network) => {
  let url = `/api/coinbase/accounts/${walletId}/addresses`;

  if(network) {
    url = `${url}?network=${network}`;
  }
  
  return axios.get(url, {
    headers: {
      'Coinbase-Access-Token': coinbaseAuthToken
    }
  }).then(response => response.data);
};


// GK 2023-10-22 - creates an address, we use this to get the SOLUSDC address, 
// because we cant get it with the getAddresses() call above (you get the ethUSDC address) 
// https://docs.cdp.coinbase.com/sign-in-with-coinbase/docs/api-addresses/
export const createAddress = (coinbaseAuthToken, walletId, data = {}) => {
  return axios.post(`/api/coinbase/accounts/${walletId}/addresses`, data, {
    headers: {
      'Coinbase-Access-Token': coinbaseAuthToken
    }
  }).then(response => response.data);
}