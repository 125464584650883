import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Space} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

import './styles/Home.css';
import walletWithLogo from '../images/wallet-with-logo-3.jpeg';
import colinLeetGrin from '../images/colin-leet-grin.jpg';
import adventurePodcasters from '../images/adventure-podcasters.jpg';
import pirateExploration from '../images/pirate-exploration.jpg';


const Podcast = () => {

    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(auth.loginDetails) {
          navigate('/transactions')
        }
      }, []);

    return (
        <div>
            <Row justify="center">
                <Col>
                    
                    {/* upgrade your wallet  */}
                    {/* <div className="container">
                        <div className="text-container">
                            
                            <h2 className="auth-title-text"> Coming Soon </h2>
                                    
                            <p className="auth-title-text">
                                The Punkypay Podcast is coming soon.
                            </p>
                            <p className="auth-title-text">
                                Check back in early 2025. Onwards and upwards!
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={walletWithLogo} alt="walletOfFuture"/>
                        </div>
                    </div>  */}


                    <div className="container">
                        <div className="text-container">
                            
                            <h2 className="auth-title-text"> Welcome to the Punkypay Podcast </h2>
                                    
                            <p className="auth-title-text">
                                The Punkypay Podcast interviews inventors, engineers, artists, entrepreneurs, and explorers. 
                                
                            </p>
                            <p className="auth-title-text">
                                Unlike other podcasts, we have first-hand experience building products and bringing them to market - so we can truly empathise with our fellow adventurers. 
                                This distinguishes our podcast from the sea of other content you'll find online.
                            </p>
                            <p className="auth-title-text">
                                We will also be discussing concepts in blockchain technology as this is Punkypay's point of excellence. 
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={adventurePodcasters} alt="adventure-podcasters"/>
                        </div>
                    </div> 


                    <div className="container">
                        <div className="text-container">
                            {/* This would be great if I had a rotating screen show thingy */}
                            <h2 className="auth-title-text"> Our latest podcast </h2>
                                    
                            <p className="auth-title-text">
                                In episode #1 of the Punkypay Podcast, Colin Leet discusses his new virtual reality video game, Tonic Melodic. Tonic Meloidic is a virtual-reality dance video game, similar to Dance Dance Revolutions or Beatsaber. 
                            </p>
                            <p className="auth-title-text">
                                The key difference between Tonic Melodic and these other games is that rather than having a programmer manually design an easy, medium, and hard mode - the game will listen to your music and choreograph a dance routine on your behalf. 
                            </p>
                            <p className="auth-title-text">
                                Listen to the <a class="light-blue" href="https://youtu.be/_jNqdH1H6qY">podcast here</a>.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={colinLeetGrin} alt="colin-leet-grin"/>
                        </div>
                    </div> 


                    <div className="container">
                        <div className="text-container">
                            
                            <h2 className="auth-title-text"> Are you an adventurer? </h2>
                                    
                            <p className="auth-title-text">
                                If you are an inventor, engineer, artist, explorer, or entrepreneur that is creating something unique we'd love to hear from you. 
                                We're also intrigued by people building the regulatory framework for new industries, such as lawyers or polticians working on blockchain technology or self-driving vehicles.
                            </p>
                            <p className="auth-title-text">
                                CIf you're interested in coming on the Punkypay Podcast, send us a message at <a class="light-blue" href="mailto:sales@punkypay.com">sales@punkypay.com</a> and link us to some of your work.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={pirateExploration} alt="pirate-exploration"/>
                        </div>
                    </div> 

                </Col>
            </Row>
        </div>
    )
}

export default Podcast;

