import { Row, Col, Typography, Divider } from 'antd';
import './styles/Support.css';  // Make sure you are using the correct CSS file
import supportArt from '../images/support-art.jpg';

const { Title, Paragraph } = Typography;

const Support = () => {
  return (
    <div className="support-container">
      <Row justify="center">
        <Col span={24} md={20} lg={18} xl={16} className="text-center">
          <Title level={1}>Support</Title>
        </Col>
      </Row>
      
      <Row justify="center">
        <Col span={24} md={20} lg={18} xl={16} className="text-center">
          <img src={supportArt} className="support-image" alt="support" />
        </Col>
      </Row>

      <Row justify="center">
        <Col span={24} md={20} lg={18} xl={16}>
          <Title level={2}>Introduction</Title>
          <Paragraph className="paragraph-custom">
            Thank you for using Punkypay! If you're confused about how to send or receive money, 
            please look at the help sections below. If you're still having troubles, please reach out 
            to us at <a href="mailto:support@punkypay.com">support@punkypay.com</a>.
          </Paragraph>
        </Col>
      </Row>

      {/* <Divider /> */}

      <Row justify="center">
        <Col span={24} md={20} lg={18} xl={16}>
          <Title level={2}>How to pay someone with Punkypay</Title>
          <Paragraph className="paragraph-custom">
            If you're trying to pay someone on Punkypay, watch this entire video. If you have any questions 
            after watching, please reach out to us at <a href="mailto:support@punkypay.com">support@punkypay.com</a>.
          </Paragraph>
          <div className="video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/k_oORxBog-g?si=xBiNVQ9Ez-AlszAY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>

      {/* <Divider /> */}

      <Row justify="center">
        <Col span={24} md={20} lg={18} xl={16}>
          <Title level={2}>How to receive money on Punkypay</Title>
          <Paragraph className="paragraph-custom">
            If you're receiving money and want to convert it into a currency other than US Dollar, Euro, or British Pound, 
            watch this video. After watching, you'll know how to receive money on your local exchange, saving you money.
          </Paragraph>
          <Paragraph className="paragraph-custom">
            If you have any questions after watching, please reach out to us at <a href="mailto:support@punkypay.com">support@punkypay.com</a>.
          </Paragraph>
          <div className="video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/NTbWREersRA?si=G_P2fMsLn0I_zswT"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Support;